import React from 'react';
import styled from 'styled-components';

import { headerHeight } from '../../components/Header/styles';
import { footerMinHeight as disclaimerFooterHeight } from '../../components/DisclaimerFooter/styles';
import { footerHeight } from '../../components/Footer/styles';
import { Layout } from '../../components';
import content from './content';
import useIsDesktop from '../../lib/isDesktop';
import { PageHeader, ContentSubheader } from '../../styles';
import { OuterContainer } from '../../components/Container/styles';
import { COLOR } from '../../constants';

export const FourOhFourContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 750px;
  width: calc(100% - 50px);
  padding: 25px 0px 75px 0px;
  flex-direction: column;
  min-height: ${`calc(100vh - ${headerHeight}px - ${disclaimerFooterHeight}px - ${footerHeight}px - 100px)`};
`;

const FourOhFourPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();

  return (
    <Layout isDesktop={isDesktop} path={path} title={content.title}>
      <OuterContainer backgroundcolor={COLOR.DARKBLUE} isDebug={false}>
        <FourOhFourContainer>
          <PageHeader>{content.header}</PageHeader>
          <ContentSubheader>{content.message}</ContentSubheader>
        </FourOhFourContainer>
      </OuterContainer>
    </Layout>
  );
};

export default FourOhFourPage;
