import { string } from 'prop-types';

interface IHomePageContent {
  title: string;
  header: string;
  message: string;
}

const content: IHomePageContent = {
  title: 'ThingCo Website',
  header: '404 - Page Not Found',
  message: 'The link you clicked may be broken or the page may have been removed.',
};

export default content;
